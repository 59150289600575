import React from "react"
import styled from "styled-components"

import Footer from "../components/Footer"
import TopBar from "../components/TopBar"
import Layout from "../containers/Layout"

import { Colors, Spacing, Typography } from "../rules"

const FourZeroFour = () => {
  return (
    <Layout>
      <TopBar />
      <Main>
        <Title>404</Title>
        <SubTitle>Página no encontrada</SubTitle>
      </Main>
      <Footer />
    </Layout>
  )
}

const Main = styled("div")`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${Colors.utility.paragraph.default};
`

const Title = styled("div")`
  text-align: center;
  font-size: ${Typography.largeTitle.fontSize};
`

const SubTitle = styled("div")`
  text-align: center;
  margin: ${Spacing.xl} 0;
  font-size: ${Typography.title.fontSize};
`

export default FourZeroFour
